var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import 'antd/lib/button/style';
import { Button } from 'antd';
import styled from '../../../styles/styled';
export var ButtonStyle = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tborder: none !important;\n\tborder-radius: 8px;\n\tcolor: ", " !important;\n\n    &.ant-btn:hover, &.ant-btn:focus, &.ant-btn-default:hover, &.ant-btn-default:focus {\n\t\tcolor: ", " !important;\n    }\n\n    &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {\n\t\tpadding-left: 40px;\n\t\t\n\t\t&.ant-btn-sm {\n\t\t\tpadding-left: 21.5px;\n\n\t\t\tspan {\n\t\t\t\tmargin-left: 5px;\n\t\t\t}\n\t\t}\n    }\n\n    &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {\n        margin-left: 0;\n    }\n"], ["\n\tborder: none !important;\n\tborder-radius: 8px;\n\tcolor: ", " !important;\n\n    &.ant-btn:hover, &.ant-btn:focus, &.ant-btn-default:hover, &.ant-btn-default:focus {\n\t\tcolor: ", " !important;\n    }\n\n    &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {\n\t\tpadding-left: 40px;\n\t\t\n\t\t&.ant-btn-sm {\n\t\t\tpadding-left: 21.5px;\n\n\t\t\tspan {\n\t\t\t\tmargin-left: 5px;\n\t\t\t}\n\t\t}\n    }\n\n    &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {\n        margin-left: 0;\n    }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.primaryColorContrast;
}, function (_a) {
    var theme = _a.theme;
    return theme.primaryColorContrast;
});
var templateObject_1;
