import axios from 'axios';
import { GAMEDATA_FETCH_FAILED, GAMEDATA_FETCHED_WITH_SUCCESS, GAMEDATA_LOADING_STARTED } from './actions-types';
export function LoadGameData() {
    return {
        type: GAMEDATA_LOADING_STARTED,
    };
}
export function GameDataFetchedWithSuccess(data) {
    return {
        type: GAMEDATA_FETCHED_WITH_SUCCESS,
        data: data,
    };
}
export function GameDataFetchFailed() {
    return {
        type: GAMEDATA_FETCH_FAILED,
    };
}
export function FetchGameData(competitionFeed, seasonId) {
    return function (dispatch) {
        dispatch(LoadGameData());
        return axios({ method: 'get', url: "https://fanarena.s3.amazonaws.com/data_" + competitionFeed + "M_" + seasonId + "_static.json" })
            .then(function (result) {
            dispatch(GameDataFetchedWithSuccess(result.data));
        })
            .catch(function (error) {
            console.log(error);
            dispatch(GameDataFetchFailed());
        });
    };
}
export var GameDataActions;
(function (GameDataActions) {
    GameDataActions.loadGameData = LoadGameData;
    GameDataActions.gameDataFetchedWithSuccess = GameDataFetchedWithSuccess;
    GameDataActions.gameDataFetchFailed = GameDataFetchFailed;
    GameDataActions.fetchGameData = FetchGameData;
})(GameDataActions || (GameDataActions = {}));
