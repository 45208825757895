var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { Redux } from '../../redux';
import { BasicTableStyle, PlayerListContainerStyle } from '../PlayerList/style';
import Button from '../UI/Button';
import Link from '../UI/Link';
var TeamsList = /** @class */ (function (_super) {
    __extends(TeamsList, _super);
    function TeamsList(props) {
        var _a;
        var _this = _super.call(this, props) || this;
        _this.handleTableChange = function (pagination) {
            var _a;
            _this.setState({ currentPage: pagination.current, fetchingRankings: true });
            console.log("FETCH");
            Redux.Actions.Leagues.fetchLeagueInfo((_a = _this.props.leagueId) !== null && _a !== void 0 ? _a : 0, _this.props.weekId, undefined, pagination.current).then(
            //todo pagination
            function (leagueInfo) {
                console.log('leagueInfo', leagueInfo);
                _this.setState({
                    rankingsResults: leagueInfo.result.data.map(function (team) { return Object.assign({}, team.team ? team.team : team, {
                        rank: (pagination.current - 1) * 10 + team.rank
                    }); }),
                    fetchingRankings: false,
                });
            });
        };
        console.log('data', props.data);
        _this.state = {
            rankingsResults: (_a = _this.props.data) === null || _a === void 0 ? void 0 : _a.map(function (team) { return Object.assign({}, team.team ? team.team : team, { rank: team.rank }); }),
            // selectedRankingsType: 'general',
            // searchRankingTerm: '',
            // selectedWeekId: 1,
            currentPage: 1,
            // totalRecordsPerPage: 10,
            fetchingRankings: false,
        };
        return _this;
    }
    TeamsList.prototype.componentDidUpdate = function (prevProps) {
        var _a;
        if (this.props.data !== prevProps.data) {
            this.setState({
                rankingsResults: (_a = this.props.data) === null || _a === void 0 ? void 0 : _a.map(function (team) { return Object.assign({}, team.team ? team.team : team, { rank: team.rank }); }),
                // selectedRankingsType: 'general',
                // searchRankingTerm: '',
                // selectedWeekId: 1,
                currentPage: 1,
                // totalRecordsPerPage: 10,
                fetchingRankings: false,
            });
        }
    };
    TeamsList.prototype.render = function () {
        var _this = this;
        var _a = this.props, data = _a.data, size = _a.size, showHeader = _a.showHeader, isLoading = _a.isLoading, isAdmin = _a.isAdmin, adminTeamId = _a.adminTeamId;
        console.log("DATA", data);
        var pagination = {
            current: this.state.currentPage,
            total: data === null || data === void 0 ? void 0 : data.totalRecords,
            pageSize: size,
        };
        var columns = [
            {
                title: '#',
                key: 'rank',
                dataIndex: 'rank',
                width: '10%',
                render: function (_text, _record, index) {
                    console.log('rankrow index', index);
                    return React.createElement("b", null, (pagination.current - 1) * pagination.pageSize + index + 1);
                },
            },
            {
                key: 'name',
                title: 'Naam',
                width: '50%',
                dataIndex: 'name',
                render: function (text, record) {
                    console.log('table', text, record);
                    return (React.createElement(React.Fragment, null,
                        React.createElement("b", null, _this.props.deadlineWeek !== 1 ? React.createElement(Link, { to: "/points/" + record.id }, text) : text),
                        React.createElement("span", { style: { display: 'block', fontSize: '12px' } }, record.name_visible ? record.first_name + " " + record.last_name : null)));
                },
            },
            {
                key: 'points',
                title: 'Punten',
                width: '20%',
                dataIndex: 'points',
                render: function (text, record) {
                    console.log('points', text, record);
                    return React.createElement("b", null, text ? text : record.totalPoints);
                },
            },
        ];
        if (isAdmin) {
            columns.push({
                key: 'action',
                title: '',
                width: '20%',
                dataIndex: 'action',
                render: function (_text, team) {
                    return ((adminTeamId && adminTeamId !== team.id && (React.createElement(Button, { onClick: function () { return _this.props.onTeamKick && _this.props.onTeamKick(team); }, size: "small" }, "Verwijder"))) || React.createElement("span", null));
                },
            });
        }
        return (React.createElement(PlayerListContainerStyle, null,
            React.createElement(BasicTableStyle, { columns: columns, dataSource: this.state.rankingsResults, showHeader: showHeader, locale: { emptyText: 'Deze minicompetitie heeft nog geen ploegen.' }, loading: isLoading, onChange: this.handleTableChange, 
                // pagination={pagination}
                rowKey: "id", rowClassName: function (_record, index) {
                    return index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even';
                } })));
    };
    return TeamsList;
}(Component));
export default TeamsList;
