import { Block, Col, Redux, Row, Title, TitleType } from '@fanarena/shared';
import parseHTML from 'html-react-parser';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { PrizesStyles, RankingPrizesStyles } from './style';

const rankingMapping: {
    [k: string]: string;
} = {
    '1': 'general',
    '2': 'week1',
    '3': 'week2',
    '4': 'week3',
};

function getImageUrl(fileName: string) {
    return `https://fanarena-admin.s3-eu-west-1.amazonaws.com/${fileName}`;
}

type PrizesProps = WithStateToProps;

type RankingPrizesProps = {
    prizes: any;
};

class RankingPrizes extends Component<RankingPrizesProps> {
    render() {
        return this.props.prizes && this.props.prizes.length
            ? this.props.prizes.map((prize: any, index: number) => {
                  return (
                      <RankingPrizesStyles key={`prize-${index}`}>
                          <Row className="prize-row" style={{ margin: 0 }}>
                              {index % 2 !== 0 ? (
                                  <React.Fragment>
                                      <Col md={12} xs={24} className="prize-body">
                                          <h2>{prize.title}</h2>
                                          {parseHTML(prize.body || '')}
                                      </Col>
                                      <Col md={12} xs={24}>
                                          <img alt="" src={prize.image} className="image-prizes-widget" />
                                      </Col>
                                  </React.Fragment>
                              ) : (
                                  <React.Fragment>
                                      <Col md={12} xs={24}>
                                          <img alt="" src={prize.image} className="image-prizes-widget" />
                                      </Col>
                                      <Col md={12} xs={24} className="prize-body">
                                          <h2>{prize.title}</h2>
                                          {parseHTML(prize.body || '')}
                                      </Col>
                                  </React.Fragment>
                              )}
                          </Row>
                      </RankingPrizesStyles>
                  );
              })
            : null;
    }
}

class Prizes extends Component<PrizesProps> {
    state = {
        prizesMap: {} as { general: string; week1: string; week2: string; week3: string },
        loaded: false,
        activeTab: 1,
    };

    componentDidMount() {
        Redux.Actions.User.getPrizes(this.props.application.competition.competitionFeed).then((result: any) => {
            const prizesList = result.map((prizeData: any) => {
                const translatedPrize = prizeData.translations.find(
                    (translation: any) => translation.languageCode === 'nl',
                );
                let imageURL = null;

                if (prizeData.image && prizeData.image.fileName) {
                    imageURL = getImageUrl(prizeData.image.fileName);
                }
                return Object.assign(
                    { firstRank: prizeData.firstRank, lastRank: prizeData.lastRank },
                    translatedPrize || {},
                    { sort: prizeData.sort, image: imageURL, ranking: prizeData.ranking },
                );
            });

            const rankingsValues = Object.keys(rankingMapping);
            const prizesGrouped = rankingsValues
                .map(rankingValue => {
                    const key: any = rankingMapping[rankingValue];
                    return {
                        [key]: prizesList
                            .filter((prizeItem: any) => prizeItem.ranking === rankingValue)
                            .sort((firstPrize: any, secondPrize: any) => firstPrize.sort - secondPrize.sort),
                    };
                })
                .reduce((acc, item) => ({ ...acc, ...item }), {});
            this.setState({ prizesMap: prizesGrouped, loaded: true });
        });
    }

    setActiveTab = (activeTab: number) => {
        this.setState({ activeTab });
    };

    render() {
        return <PrizesStyles>
            <Title type={TitleType.h3}>Prijzen</Title>
            <Row gutter={[16, 16]}>
                <Col md={16}>
                    <Block>
                        <p>Akkoord, Tourmanager speel je vooral voor de eer en voor de strijd onder vrienden in je minicompetitie. Toch zijn er ook dit jaar enkele leuke prijzen te winnen.</p>
                        <Row className="prize-row" style={{ margin: '20px auto', padding: '20px', borderRadius: '5px', background: '#1E1F1D' }}>
                            <React.Fragment>
                                <Col md={12} xs={24} className="prize-body">
                                    <h2 style={{color: '#E51C21', fontWeight: 'bold'}}>Eindklassement - 1ste plaats</h2>
                                    Win een Ridley Fenix SliC ter waarde van 2.999 euro in jouw favoriete kleuren. De Fenix biedt de gedroomde mix van snelheid en comfort en is jouw ideale metgezel voor de klassiekers en fietsvakanties. Ridley beschikt over een eigen lakkerij en biedt via de configurator de kans om het design zelf te bepalen!
                                </Col>
                                <Col md={12} xs={24}>
                                    <img alt="prijs-winnaar-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_1st_place_txt_tour24.jpg" className="image-prizes-widget" />
                                </Col>
                            </React.Fragment>
                        </Row>
                        <Row className="prize-row" style={{ margin: '20px auto', padding: '20px', borderRadius: '5px', background: '#1E1F1D' }}>
                            <React.Fragment>
                                <Col md={12} xs={24} className="prize-body">
                                    <h2 style={{color: '#E51C21', fontWeight: 'bold'}}>Eindklassement - 2de en 3de plaats</h2>
                                    Never stop cycling met de ultieme GPS-fietscomputer op zonne-energie waar je op kunt vertrouwen wanneer je hem het meest nodig hebt.
                                    <p>Edge 1040 Solar is klaar voor elke rit, van afgelegen, onverharde paden tot onvergetelijke klimtochten.</p>
                                </Col>
                                <Col md={12} xs={24}>
                                    <img alt="prijs-tweede-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_2nd_place_txt_tour24.jpg" className="image-prizes-widget" />
                                </Col>
                            </React.Fragment>
                        </Row>
                        <Row className="prize-row" style={{ margin: '20px auto', padding: '20px', borderRadius: '5px', background: '#1E1F1D' }}>
                            <React.Fragment>
                                <Col md={12} xs={24} className="prize-body">
                                    <h2 style={{color: '#E51C21', fontWeight: 'bold'}}>Eindklassement - 1ste tem 10de plaats</h2>
                                    Win een heerlijke geschenkverpakking van hét bier van de koers, Kwaremont! Het is net als de befaamde kuitenbijter uit de Vlaamse Ardennen, de Oude Kwaremont, karaktervol en erg pittig.
                                    <p>Het is een volmout bier dat zorgt voor een flinke dosis vloeibare suikers na een stevige inspanning. Je beloning? Een uitgesproken dorstlessende afdronk... met een stijgingspercentage van 6,6%. Enjoy!</p>
                                </Col>
                                <Col md={12} xs={24}>
                                    <img alt="top-10-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/top10_tour24.jpg" className="image-prizes-widget" />
                                </Col>
                            </React.Fragment>
                        </Row>
                        <Row className="prize-row" style={{ margin: '20px auto', padding: '20px', borderRadius: '5px', background: '#1E1F1D' }}>
                            <React.Fragment>
                                <Col md={12} xs={24} className="prize-body">
                                    <h2 style={{color: '#E51C21', fontWeight: 'bold'}}>Dagwinnaar</h2>
                                    Als jarenlange partner van de Tour de France is Škoda trots om de groene trui te sponsoren, maar ook om deze mondiale wielerwedstrijd te voorzien met meer dan 200 ruime en betrouwbare wagens! Om dit onder de aandacht te brengen biedt Škoda, als supporter van de grootste fietsfamilie, een exclusieve groene trui uit de Tour de France aan elke dagwinnaar. Alvast heel veel succes! #WeLoveCycling
                                </Col>
                                <Col md={12} xs={24}>
                                    <img alt="speeldagprijs-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_3rd_place_txt_tour24.jpg" className="image-prizes-widget" />
                                </Col>
                            </React.Fragment>
                        </Row>
                        {/* <Row className="prize-row" style={{ margin: '20px auto', padding: '20px', borderRadius: '5px', background: '#1E1F1D' }}>
                            <React.Fragment>
                                <Col md={12} xs={24} className="prize-body">
                                    <h2 style={{color: '#E51C21', fontWeight: 'bold'}}>Top 10</h2>
                                    Win 10x €150 in sportvoeding van 6d Sports Nutrition. 6d is een Belgisch sportvoedingsmerk waar kwaliteit en wetenschap primeren. Hun ruim aanbod sportvoeding is er voor elke gedreven atleet die zijn prestaties wenst te maximaliseren. 6d is onder andere trouwe partner van Soudal Quick-Step en de Red Lions.
                                </Col>
                                <Col md={12} xs={24}>
                                    <img alt="eindprijs-sports-nutrition-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_top10_txt_klas24.jpg" className="image-prizes-widget" />
                                </Col>
                            </React.Fragment>
                        </Row>
                        <Row className="prize-row" style={{ margin: '20px auto', padding: '20px', borderRadius: '5px', background: '#1E1F1D' }}>
                            <React.Fragment>
                                <Col md={12} xs={24} className="prize-body">
                                    <h2 style={{color: '#E51C21', fontWeight: 'bold'}}>Top 5</h2>
                                    Win een Decca voucher twv 50 euro en beleef jouw eigen wielermomenten in stijl! Decca, hét merk voor fietskleding en accessoires, staat garant voor hoogwaardige producten die jouw fietservaring naar een hoger niveau tillen. Met een ruim assortiment aan kleding voor zowel pedaleurs de charme als professionele renners, ben jij klaar om te schitteren op de fiets. Van ademende shirts tot comfortabele broeken, Decca voorziet jou van alles wat je nodig hebt om vol vertrouwen de weg op te gaan.
                                </Col>
                                <Col md={12} xs={24}>
                                    <img alt="eindprijs-deca-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/deca-prijs.png" className="image-prizes-widget" />
                                </Col>
                            </React.Fragment>
                        </Row>
                        <Row className="prize-row" style={{ margin: '20px auto', padding: '20px', borderRadius: '5px', background: '#1E1F1D' }}>
                            <React.Fragment>
                                <Col md={12} xs={24} className="prize-body">
                                    <h2 style={{color: '#E51C21', fontWeight: 'bold'}}>Mewa-minicompetitie</h2>
                                    Doe mee met de Mewa-minicompetitie en win een unieke prijs.
                                    <p>Als je ploeg op kop staat in deze mini competitie na Classic Brugge–De Panne dan mag je het Mewa-konvooi vervoegen tijdens Gent-Wevelgem. Beleef deze klassieker vanuit de buik van het peloton.</p>
                                </Col>
                                <Col md={12} xs={24}>
                                    <img alt="speeldagprijs-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_league_mewa_txt_klas24.jpg" className="image-prizes-widget" />
                                </Col>
                            </React.Fragment>
                        </Row> */}
                    </Block>
                </Col>
                <Col md={8}>
                    <Block>
                        <a href='https://www.garmin.com/nl-BE/'><img alt="garmin_wielermanager_prijs" src='https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/partner_garmin.jpg'/></a>
                        <a href='https://www.ridley-bikes.com/nl_BE'><img alt="ridley_wielermanager_prijs" src='https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/partner_ridley.jpg'/></a>
                    </Block>
                </Col>
            </Row>
        </PrizesStyles>
        // const blockStyle = { marginBottom: '15px', paddingTop: 0 };
        // const { activeTab } = this.state;

        // return this.state.loaded ? (
        //     <PrizesStyles>
        //         <Row>
        //             <ul className="prizes-tabs">
        //                 <li className={activeTab === 1 ? 'active' : ''} onClick={(e: any) => this.setActiveTab(1)}>
        //                     Algemene
        //                 </li>
        //                 <li className={activeTab === 2 ? 'active' : ''} onClick={(e: any) => this.setActiveTab(2)}>
        //                     Week
        //                 </li>
        //             </ul>
        //         </Row>
        //         <Row>
        //             <Col span={24}>
        //                 {activeTab === 1 ? (
        //                     <Block style={blockStyle}>
        //                         <RankingPrizes prizes={this.state.prizesMap.general} />
        //                     </Block>
        //                 ) : null}
        //                 {activeTab === 2 ? (
        //                     <React.Fragment>
        //                         <Block style={blockStyle}>
        //                             <Title type={TitleType.h3}>Week 1</Title>
        //                             <RankingPrizes prizes={this.state.prizesMap.week1} />
        //                         </Block>

        //                         <Block style={blockStyle}>
        //                             <Title type={TitleType.h3}>Week 2</Title>
        //                             <RankingPrizes prizes={this.state.prizesMap.week2} />
        //                         </Block>

        //                         <Block style={blockStyle}>
        //                             <Title type={TitleType.h3}>Week 3</Title>
        //                             <RankingPrizes prizes={this.state.prizesMap.week3} />
        //                         </Block>
        //                     </React.Fragment>
        //                 ) : null}
        //             </Col>
        //         </Row>
        //     </PrizesStyles>
        // ) : null;
    }
}

function mapStateToProps({ application }: Redux.StoreState) {
    return {
        application,
    };
}
type WithStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, {})(Prizes);
