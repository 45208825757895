import {H1, H2, MediaQueries, styled} from "@fanarena/shared";

interface HomeStyleProps {
    competitionfeed?: string;
}

const HomeStyle = styled.div<HomeStyleProps>`
    .cover {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: url('https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/cover_mobile_${props => props.competitionfeed || 'klas'}24.jpg') no-repeat top center / cover;
        padding: 20px;
        margin: 10px auto;
        min-height: 280px;


        @media ${MediaQueries.tablet} {
            background: url('https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/cover_${props => props.competitionfeed || 'klas'}24.jpg') no-repeat right center / cover;
        }

        font-weight: 100;

        .countdown {
            font-size: 18px;
        }

        ${H1} {
            font-size: 16px;
            color: white;
            margin: 0;
            
            @media ${MediaQueries.tablet} {
                font-size: 30px;
            }
        }

        ${H2} {
            margin: 40px 0 20px;
        }

        .win {
            // color: #E51C21;
            color: #FFF;
            font-size: 12px;
            margin-bottom: 10px;

            @media ${MediaQueries.tablet} {
                font-size: 21px;
            }
        }

        p {
            color: #fff;
            font-size: 18px;
        }
    }
`;

const CardStyle = styled.div`
    margin-bottom: 40px;
    text-align: center;

    .ant-card {
        border-radius: 8px;
        min-height: 440px;
        background-color: #1E1F1D;
    }

    .ant-card-cover img {
        border-radius: 8px 8px 0 0;
    }

    .ant-card-meta-title {
        display: inline-block;
        padding: 0px;
        color: #FFF;
        font-size: 26px;
    }

    .ant-card-meta-description {
        color: #FFF;
    }
`;

export {
    HomeStyle,
    CardStyle
};