import {
    Block,
    Button,
    Col,
    Redux,
    Row,
    Title,
    TitleType,
} from '@fanarena/shared';
import { Icon } from 'antd';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';

import { ChooseGameStyles } from './style';

type Props = RouteComponentProps & WithStateToProps & {
    switchCompetitionFeed: any;
};

class ChooseGameContainer extends Component<Props> {
    _handleNavigate = (competitionFeed: any) => {
        this.props.switchCompetitionFeed(competitionFeed);
        this.props.history.push('/new');
    }

    render() {
        // const team = this.props.user && this.props.user.teams && this.props.user.teams[0];

        return (
            <ChooseGameStyles>
                {/* {team && team.id && <Redirect to={{ pathname: `/edit/${team.id}` }} />} */}
                <Row gutter={[16, 16]}>
                    <Col md={24} sm={24} xs={24}>
                        <Title type={TitleType.h3}>Selecteer een competitie</Title>
                        <Block>
                        <div key={"TOUR-men"}>
                            <div onClick={() => this._handleNavigate("TOUR")} className="hoverEffect">
                                {'Tour de France'}
                                <Icon type="caret-right" theme="filled" /> {/* Replace <Icon /> with your actual icon component */}
                            </div>
                        </div>
                        <div key={"KLASM-men"}>
                            <div onClick={() => this._handleNavigate("KLASM")} className="hoverEffect">
                                Voorjaarsklassiekers mannen
                                <Icon type="caret-right" theme="filled" /> {/* Replace <Icon /> with your actual icon component */}
                            </div>
                        </div>
                        <div key={"KLASF-women"}>
                            <div onClick={() => this._handleNavigate("KLASF")} className="hoverEffect">
                                Voorjaarsklassiekers vrouwen
                                <Icon type="caret-right" theme="filled" /> {/* Replace <Icon /> with your actual icon component */}
                            </div>
                        </div>
                        </Block>
                    </Col>
                </Row>
            </ChooseGameStyles>
        );
    }
}

const mapDispatchToProps = {
    switchCompetitionFeed: Redux.Actions.Application.switchCompetitionFeed,
};

function mapStateToProps({ application }: Redux.StoreState) {
    return {
        application
    };
}
type WithStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ChooseGameContainer);
