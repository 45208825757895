import {
    BasicTableStyle,
    Block,
    Col,
    Link,
    PlayerListContainerStyle,
    Redux,
    Row,
    Select,
    Tabs,
    Title,
    TitleType,
} from '@fanarena/shared';
import { Card,Tabs as antTabs } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CardStyle } from '../Home/style';
import config from './../../config';
import { selectTopMarginOnSmallerScreens } from './style';

const { Meta } = Card;
const SelectStyled = selectTopMarginOnSmallerScreens(Select);

const TabPane = antTabs.TabPane;

type Props = WithStateToProps;

export interface State {
    rankingsResults: any;
    selectedRankingsType: string;
    searchRankingTerm: string;
    currentPage: number;
    selectedWeekId: number;
    totalRecordsPerPage: number;
    fetchingRankings: boolean;
}

class Leagues extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            rankingsResults: [],
            selectedRankingsType: 'general',
            searchRankingTerm: '',
            selectedWeekId: 1,
            currentPage: 1,
            totalRecordsPerPage: 10,
            fetchingRankings: false,
        };
    }

    componentDidMount() {
        this.fetchRankings(
            this.state.selectedRankingsType,
            this.state.totalRecordsPerPage,
            this.state.currentPage,
            this.state.searchRankingTerm,
            this.state.selectedWeekId,
        );
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (
            this.props.matches.info.deadlineWeek &&
            this.props.matches.info.deadlineWeek !== prevProps.matches.info.deadlineWeek
        ) {
            const deadlineWeekId = this.props.matches.info.deadlineWeek;
            this.setState({
                selectedWeekId: deadlineWeekId > 1 ? deadlineWeekId - 1 : 1,
            });
        }

        if(this.props.application.competition.competitionFeed && prevProps.application.competition.competitionFeed !== this.props.application.competition.competitionFeed) {
            this.fetchRankings(
                this.state.selectedRankingsType,
                this.state.totalRecordsPerPage,
                this.state.currentPage,
                this.state.searchRankingTerm,
                this.state.selectedWeekId,
            );
        }
    
    }

    fetchRankings = (
        selectedRankingsType: any,
        totalRecordsPerPage: any,
        currentPage: any,
        searchRankingTerm: any,
        selectedWeekId: any,
    ) => {
        this.setState({ fetchingRankings: true });
        const rankingTypeId = selectedRankingsType.indexOf('custom') !== -1 ? selectedWeekId : null;
        const rankingType = selectedRankingsType.indexOf('custom') !== -1 ? 'custom' : selectedRankingsType;

        return Redux.Actions.Teams.getRankings(
            this.props.application.competition.competitionFeed,
            rankingType,
            totalRecordsPerPage,
            currentPage,
            searchRankingTerm,
            selectedWeekId,
            rankingTypeId,
        ).then(result => {
            this.setState({ rankingsResults: result, fetchingRankings: false });
        });
    };

    onSearchTermChanged = (e: any) => {
        this.setState({ searchRankingTerm: e.target.value });
    };

    onTabChange = (rankingType: string) => {
        const deadlineWeekId = this.props.matches.info.deadlineWeek;

        const newStateObject = {
            selectedRankingsType: rankingType,
            currentPage: 1,
            searchRankingTerm: '',
            rankingsResults: [],
            selectedWeekId:
                rankingType === 'custom' ? 2 : deadlineWeekId && deadlineWeekId > 1 ? deadlineWeekId - 1 : 1,
        };

        if (['pundits'].includes(rankingType)) {
            newStateObject.selectedWeekId = -1;
        }

        this.setState(newStateObject);

        if (rankingType === 'pundits') {
            this.fetchLeagueInfo();
        } else {
            this.fetchRankings(
                rankingType,
                this.state.totalRecordsPerPage,
                this.state.currentPage,
                this.state.searchRankingTerm,
                newStateObject.selectedWeekId,
            );
        }
    };

    fetchLeagueInfo(weekId?: number) {
        const team = this.props.user && this.props.user.teams && this.props.user.teams[0];
        if (team) {
            Redux.Actions.Leagues.fetchLeagueInfo(config.EXPERTS_LEAGUE_ID, weekId && weekId > 0 ? weekId : null, [
                team.id,
            ]).then((leagueInfo: any) => {
                const punditsData = leagueInfo.teams.map((teamItem: any, index: number) => {
                    return {
                        team: {
                            id: teamItem.id,
                            name: teamItem.name,
                            points: teamItem.points,
                            rank: index + 1,
                        },
                        user: teamItem.user,
                    };
                });
                this.setState({
                    rankingsResults: { data: punditsData },
                    fetchingRankings: false,
                });
            });
        }
    }

    handleTableChange = (pagination: any, filters: any, sorter: any) => {
        this.setState({ currentPage: pagination.current });

        this.fetchRankings(
            this.state.selectedRankingsType,
            this.state.totalRecordsPerPage,
            pagination.current,
            this.state.searchRankingTerm,
            this.state.selectedWeekId,
        );
    };

    onSearchByName = () => {
        const rankingType =
            this.state.selectedRankingsType === 'custom' && this.state.selectedWeekId > 0
                ? 'week'
                : this.state.selectedRankingsType;

        this.fetchRankings(
            rankingType,
            this.state.totalRecordsPerPage,
            this.state.currentPage,
            this.state.searchRankingTerm,
            this.state.selectedWeekId,
        );
    };

    onWeekSelect = (weekId: any) => {
        this.setState({ selectedWeekId: weekId });

        if (this.state.selectedRankingsType === 'pundits') {
            this.fetchLeagueInfo(weekId);
        } else {
            const rankingType =
                this.state.selectedRankingsType === 'custom' && weekId > 0 ? 'custom' : this.state.selectedRankingsType;

            this.fetchRankings(
                rankingType,
                this.state.totalRecordsPerPage,
                this.state.currentPage,
                this.state.searchRankingTerm,
                weekId,
            );
        }
    };

    render() {
        const {
            searchRankingTerm,
            rankingsResults,
            totalRecordsPerPage,
            fetchingRankings,
            currentPage,
            selectedRankingsType,
            selectedWeekId,
        } = this.state;

        const columns = [
            {
                title: '#',
                key: 'rank',
                dataIndex: 'team',
                width: '20%',
                render: (team: any, record: any) => {
                    return <b>{record.rank}</b>;
                },
            },
            {
                title: 'Team',
                key: 'team',
                width: '60%',
                dataIndex: 'team',
                render: (team: any, record: any) => {
                    return (
                        <React.Fragment>
                            {/* {this.props.matches.info.deadlineWeek !== 1 && record.rank <= 3 ? ( */}
                                {/* <Link to={`/points/${record.teamId}`}> */}
                                    {record.teamName}
                                {/* </Link> */}
                            {/* ) : ( */}
                                {/* <b>{record.teamName}</b> */}
                            {/* )} */}
                            <span style={{ display: 'block', fontSize: '12px', color: '#9FA8AD' }}>
                                {record.nameVisible === 'true' ? `${record.firstName} ${record.lastName}` : null}
                            </span>
                        </React.Fragment>
                    );
                },
            },
            {
                title: 'Punten',
                key: 'points',
                width: '20%',
                dataIndex: 'team',
                render: (team: any, record: any) => {
                    return <span>{record.points}</span>;
                },
            },
        ];

        const pagination = {
            current: currentPage,
            total: rankingsResults.totalRecords,
            pageSize: totalRecordsPerPage,
        };

        let weeks = this.props.matches.weeks.map((week: any) => {
            return {
                id: week.weekId,
                name: `Rit ${week.weekId}`,
            };
        });

        const weeksRanking = this.props.matches.weeks
            .filter((week: any) => [1, 2, 3].includes(week.weekId))
            .map((week: any) => {
                return {
                    id: week.weekId + 1,
                    name: `Rit ${week.weekId}`,
                };
            });

        const customWeekIds = [4, 8, 10, 14];

        if (selectedRankingsType === 'custom') {
            weeks = weeks.filter((week: any) => customWeekIds.includes(week.id));
        }

        if (selectedRankingsType === 'custom' || selectedRankingsType === 'pundits') {
            weeks.unshift({ id: -1, name: 'Alle Speeldagen' });
        }

        return (
            <Row>
                <Col lg={{ span: 20, offset: 2 }}>
                        <Title type={TitleType.h3}>Rangschikking</Title>
                        <Block>
                            <Row gutter={[16, 16]}>
                            <Tabs defaultActiveKey="general" size={'large'} onChange={this.onTabChange}>
                                <TabPane tab="Algemeen" key="general" />
                                <TabPane tab="Ritten" key="week" />
                                {/* <TabPane tab="Week" key="custom" />
                                <TabPane tab="Sporza Wielerkenners" key="pundits" /> */}
                            </Tabs>

                            {/* {(selectedRankingsType !== 'pundits' && (
                                <Col md={12} sm={24} xs={24}>
                                    <span style={{ width: '80%', display: 'inline-block' }}>
                                        <Input
                                            onChange={this.onSearchTermChanged}
                                            placeholder={'Zoek op naam'}
                                            value={searchRankingTerm}
                                        />
                                    </span>

                                    <span style={{ width: '20%', display: 'inline-block' }}>
                                        <div style={{ marginLeft: '5px' }}>
                                            <Button
                                                style={{ marginTop: '5px' }}
                                                disabled={searchRankingTerm.length > 0 && searchRankingTerm.length < 3}
                                                onClick={this.onSearchByName}
                                                size="small"
                                            >
                                                Zoek
                                            </Button>
                                        </div>
                                    </span>
                                </Col>
                            )) ||
                                null} */}

                            {(['week', 'custom', 'pundits'].indexOf(selectedRankingsType) !== -1 && (
                                <Col md={12} sm={24} xs={24}>
                                    <SelectStyled
                                        keyProperty={'id'}
                                        style={{ width: '100%' }}
                                        value={selectedWeekId}
                                        notFoundContent={'No weeks.'}
                                        textProperty={'name'}
                                        onSelect={this.onWeekSelect}
                                        values={selectedRankingsType === 'custom' ? weeksRanking : weeks}
                                    />
                                </Col>
                            )) ||
                                null}

                            <Col md={24} sm={24} xs={24} style={{ marginTop: '10px' }}>
                                <PlayerListContainerStyle>
                                   {(this.state.fetchingRankings ? <div>Rangschikking wordt geladen</div>
                                     : this.state.rankingsResults.data) ? <BasicTableStyle
                                        columns={columns}
                                        dataSource={rankingsResults.data}
                                        showHeader={true}
                                        locale={{ emptyText: 'Rangschikking nog niet beschikbaar' }}
                                        loading={fetchingRankings}
                                        pagination={pagination}
                                        onChange={this.handleTableChange}
                                        rowKey={(record: any, index: number) => `record-${index + 1}`}
                                        rowClassName={(record: any, index: number) =>
                                            index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'
                                        }
                                    />
                                    : <div>Rangschikking nog niet beschikbaar</div>}
                                </PlayerListContainerStyle>
                            </Col>
                            </Row>
                            {/* {(['general'].indexOf(selectedRankingsType) !== -1 && (<Row gutter={[16, 16]} type="flex" justify="space-around" align="middle">
                                <Col md={8} sm={8} xs={24}>
                                    <div style={{textAlign: 'center', background: '#3C3C3C', borderRadius: '5px', padding: '10px'}}>
                                        <div>
                                            <img style={{maxWidth: '200px'}} alt="hoofdprijs" src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_1st_place_tour24.jpg" />
                                        </div>
                                        <div style={{margin: '10px'}}><span style={{color: '#E51C21', fontWeight: 'bold'}}>Ridley Fenix Disc</span><p>Eerste plaats eindklassement</p></div>
                                    </div>
                                </Col>
                                <Col md={8} sm={8} xs={24}>
                                    <div style={{textAlign: 'center', background: '#3C3C3C', borderRadius: '5px', padding: '10px'}}>
                                        <div>
                                            <img style={{maxWidth: '200px'}} alt="hoofdprijs" src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_2nd_place_tour24.jpg" />
                                        </div>
                                        <div style={{margin: '10px'}}><span style={{margin: '10px', color: '#E51C21', fontWeight: 'bold'}}>Garmin Edge 1040 Solar</span><p>2e en 3e plaats eindklassement</p></div>
                                    </div>
                                </Col>
                                <Col md={8} sm={8} xs={24}>
                                    <div style={{textAlign: 'center', background: '#3C3C3C', borderRadius: '5px', padding: '10px'}}>
                                        <div>
                                            <img style={{maxWidth: '200px'}} alt="hoofdprijs" src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_3rd_place_tour24.jpg" />
                                        </div>
                                        <div style={{margin: '10px'}}><span style={{margin: '10px', color: '#E51C21', fontWeight: 'bold'}}>Skoda groene trui</span><p>Dagwinnaar</p></div>
                                    </div>
                                </Col>
                            </Row>)) || null} */}
                        </Block>
                        </Col>
            </Row>
        );
    }
}

const mapDispatchToProps = {
    fetchTeamLeagues: Redux.Actions.User.fetchUserTeamLeaguesAndPersist,
};

function mapStateToProps({ application, matches, user }: Redux.StoreState) {
    return {
        application,
        matches,
        user,
    };
}
type WithStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Leagues);
