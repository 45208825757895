import {styled} from "@fanarena/shared";

const ChooseGameStyles = styled.div`
.hoverEffect {
    font-size 18px;
    padding: 20px;
    margin: 20px;
    background: #1E1F1D;
    display: flex;
    align-items: center;
    border: 1px solid #1E1F1D;
    justify-content: space-between;
    cursor: pointer;
}

.hoverEffect:hover {
    border: 1px solid #E51C21; /* Change color on hover */

    .anticon-caret-right {
        color: #E51C21;
    }
}
`;

export {
    ChooseGameStyles,
};
