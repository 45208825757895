var _a = process.env, REACT_APP_API_URL = _a.REACT_APP_API_URL, REACT_APP_BUGSNAG_KEY = _a.REACT_APP_BUGSNAG_KEY, REACT_APP_COMPETITION_CONFIG = _a.REACT_APP_COMPETITION_CONFIG, REACT_APP_LOCKR_PREFIX = _a.REACT_APP_LOCKR_PREFIX;
var config = {
    API_URL: REACT_APP_API_URL,
    HASH_SALT: 'VUEL2025',
    EXPERTS_LEAGUE_ID: 16311,
    BUGSNAG_KEY: REACT_APP_BUGSNAG_KEY || '',
    LOCKR_PREFIX: REACT_APP_LOCKR_PREFIX,
    COMPETITION_CONFIG: REACT_APP_COMPETITION_CONFIG && REACT_APP_COMPETITION_CONFIG.length
        ? JSON.parse(REACT_APP_COMPETITION_CONFIG) : {},
};
export default config;
